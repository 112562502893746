// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile__dialog .MuiDialog-paper {
  margin: 0 !important;
}

.mobile__dialog .MuiDialog-paperScrollPaper {
  max-height: 100% !important;
}

.mobile__dialog .MuiDialog-paperFullWidth {
  width: 100%;
}

.row__colour {
  background-color: #f7f7f7;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/reports/css/hours.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".mobile__dialog .MuiDialog-paper {\n  margin: 0 !important;\n}\n\n.mobile__dialog .MuiDialog-paperScrollPaper {\n  max-height: 100% !important;\n}\n\n.mobile__dialog .MuiDialog-paperFullWidth {\n  width: 100%;\n}\n\n.row__colour {\n  background-color: #f7f7f7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
