// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled {
  background-color: #f1f1f1;
  color: #8e949e;
}

.time-picker-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.col-time-picker {
  min-width: 300px;
}

.rounded {
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/_time-picker-input.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".disabled {\n  background-color: #f1f1f1;\n  color: #8e949e;\n}\n\n.time-picker-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n\n.col-time-picker {\n  min-width: 300px;\n}\n\n.rounded {\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
