// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a:hover {
  text-decoration: none !important;
}

.margin-page {
  min-height: 90%; /* Fallback for browsers do NOT support vh unit */
  min-height: 90vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.containers {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.07);
}

.btn-light {
  border-color: #b4b4b4 !important;
}

.primary-color {
  color: #a50808;
}

.row {
  padding: 10px;
}

.google__logo__size {
  width: 1.2rem;
}

.google__logo__text {
  margin-left: 0.5rem;
}

.primary__text__size {
  font-size: 0.875rem;
}

.image__column__padding {
  padding: 0 !important;
}

.ftw__logo {
  width: 100px;
  height: 100px;
}

.mobile__view__margin {
  margin: auto;
}

.header__label {
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
}

/* MEDIA QUERIES  */

/* Phone View */
@media (max-width: 37.5em) {
  .crop {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }

  .image__view {
    /* height: 300px; */
    margin: -145px 0 0 0;
  }

  .mobile__view__padding {
    padding: 0;
  }

  .mobile__view__margin {
    margin-top: -25%;
  }
} ;
`, "",{"version":3,"sources":["webpack://./src/layouts/login/login.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,eAAe,EAAE,iDAAiD;EAClE,gBAAgB,EAAE,iDAAiD;;EAEnE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA,mBAAmB;;AAEnB,eAAe;AACf;EACE;IACE,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,oBAAoB;EACtB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":["a:hover {\n  text-decoration: none !important;\n}\n\n.margin-page {\n  min-height: 90%; /* Fallback for browsers do NOT support vh unit */\n  min-height: 90vh; /* These two lines are counted as one :-)       */\n\n  display: flex;\n  align-items: center;\n}\n\n.containers {\n  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.07);\n}\n\n.btn-light {\n  border-color: #b4b4b4 !important;\n}\n\n.primary-color {\n  color: #a50808;\n}\n\n.row {\n  padding: 10px;\n}\n\n.google__logo__size {\n  width: 1.2rem;\n}\n\n.google__logo__text {\n  margin-left: 0.5rem;\n}\n\n.primary__text__size {\n  font-size: 0.875rem;\n}\n\n.image__column__padding {\n  padding: 0 !important;\n}\n\n.ftw__logo {\n  width: 100px;\n  height: 100px;\n}\n\n.mobile__view__margin {\n  margin: auto;\n}\n\n.header__label {\n  font-family: Arial;\n  font-size: 20px;\n  font-weight: bold;\n}\n\n/* MEDIA QUERIES  */\n\n/* Phone View */\n@media (max-width: 37.5em) {\n  .crop {\n    width: 100%;\n    height: 300px;\n    overflow: hidden;\n  }\n\n  .image__view {\n    /* height: 300px; */\n    margin: -145px 0 0 0;\n  }\n\n  .mobile__view__padding {\n    padding: 0;\n  }\n\n  .mobile__view__margin {\n    margin-top: -25%;\n  }\n} ;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
