// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageArrow {
  transform: rotate(90deg);
}

.imageArrowMobile {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
}

.imageDots .btn-default {
  transform: rotate(0deg);
  border: none;
  cursor: pointer;
  background-color: #fff4f4;
}

p {
  font-size: 14px;
}

.row_border_bottom {
  border-bottom: 1px solid lightgray;
}

.row_border {
  border: 1px solid lightgray;
}

.number__Timesheet {
  border: 1px solid black;
  border-radius: 50px;
  padding: 0px 10px 0px 10px;
}

.bi-three-dots-vertical {
  color: #222222;
}

.viewTimesheetEllipses .nav-link {
  padding-right: 0;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip::after {
  content: attr(title);
  visibility: hidden;
}
.tooltip:hover::after {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/Timesheet/css/addNewTimesheet.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".imageArrow {\n  transform: rotate(90deg);\n}\n\n.imageArrowMobile {\n  transform: rotate(90deg);\n  width: 20px;\n  height: 20px;\n}\n\n.imageDots .btn-default {\n  transform: rotate(0deg);\n  border: none;\n  cursor: pointer;\n  background-color: #fff4f4;\n}\n\np {\n  font-size: 14px;\n}\n\n.row_border_bottom {\n  border-bottom: 1px solid lightgray;\n}\n\n.row_border {\n  border: 1px solid lightgray;\n}\n\n.number__Timesheet {\n  border: 1px solid black;\n  border-radius: 50px;\n  padding: 0px 10px 0px 10px;\n}\n\n.bi-three-dots-vertical {\n  color: #222222;\n}\n\n.viewTimesheetEllipses .nav-link {\n  padding-right: 0;\n}\n\n.tooltip {\n  position: relative;\n  display: inline-block;\n  cursor: pointer;\n}\n\n.tooltip::after {\n  content: attr(title);\n  visibility: hidden;\n}\n.tooltip:hover::after {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
