// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image__sidebar__logo {
  position: fixed;
  z-index: 100;
  padding-left: 25px;
  padding-top: 11px;
  padding-right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MobileSidebarIcon/MobileSidebarIcon.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".image__sidebar__logo {\n  position: fixed;\n  z-index: 100;\n  padding-left: 25px;\n  padding-top: 11px;\n  padding-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
