// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-b {
  font-weight: bold;
  color: #595959;
}

.font-size {
  font-size: 1.3em;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/app-terms-policy/app-terms-policy.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".font-b {\n  font-weight: bold;\n  color: #595959;\n}\n\n.font-size {\n  font-size: 1.3em;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
