// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .form-group {
  margin-bottom: 0 !important;
} */

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.ellipse__mobile {
  margin-right: -13px;
  margin-top: -45px;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/settings/Users/mobile-layouts/UsersTableMobileView.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["/* .form-group {\n  margin-bottom: 0 !important;\n} */\n\n.MuiCardContent-root:last-child {\n  padding-bottom: 0 !important;\n}\n\n.ellipse__mobile {\n  margin-right: -13px;\n  margin-top: -45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
