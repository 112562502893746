// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon_arrow {
  transform: rotate(270deg);
  width: 16px;
  height: 16px;
}

.hr__row {
  margin: 0;
}

.small__profile__pic {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #d9d9d94d;
  z-index: 10;
}

.modal__image {
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/settings/css/users.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".icon_arrow {\n  transform: rotate(270deg);\n  width: 16px;\n  height: 16px;\n}\n\n.hr__row {\n  margin: 0;\n}\n\n.small__profile__pic {\n  height: 36px;\n  width: 36px;\n  border-radius: 50%;\n  background-color: #d9d9d94d;\n  z-index: 10;\n}\n\n.modal__image {\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
