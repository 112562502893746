// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .popup-directory .css-yt9ioa-option,
.popup-directory .css-1n7v3ny-option {
  padding-left: 50px !important;
}

.popup-directory #react-select-3-option-0 {
  padding-left: 12px !important;
}

.popup-directory .css-18ng2q5-group {
  text-transform: capitalize;
  font-size: 100%;
  font-weight: 700;
} */
`, "",{"version":3,"sources":["webpack://./src/layouts/settings/custom-popups/css/custom_popup.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;GAaG","sourcesContent":["/* .popup-directory .css-yt9ioa-option,\n.popup-directory .css-1n7v3ny-option {\n  padding-left: 50px !important;\n}\n\n.popup-directory #react-select-3-option-0 {\n  padding-left: 12px !important;\n}\n\n.popup-directory .css-18ng2q5-group {\n  text-transform: capitalize;\n  font-size: 100%;\n  font-weight: 700;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
