// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiStepIcon-root.MuiStepIcon-completed {
  color: #a50808 !important;
}

.MuiStepIcon-active {
  color: #a50808 !important;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #a50808 !important;
}

.MuiButton-label {
  color: #a50808 !important;
}

.MuiPickersClockPointer-thumb {
  border-color: #a50808 !important;
}

.MuiStepLabel-root {
  display: contents !important;
}

.MuiStepLabel-iconContainer {
  padding-bottom: 8px !important;
}

.MuiStepIcon-root {
  margin: auto !important;
}

.MuiStepper-horizontal {
  width: 30rem !important;
  margin: auto !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.ellipses:hover {
  background-color: lightskyblue;
}

.column__width {
  padding: 0 !important;
}

.payment__modal {
}

@media only screen and (max-width: 812px) {
  .MuiStepper-horizontal {
    width: 22rem !important;
    margin: auto !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/layouts/Timesheet/css/editTimesheet.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;;;;;EAKE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;AACA;;AAEA;EACE;IACE,uBAAuB;IACvB,uBAAuB;EACzB;AACF","sourcesContent":[".MuiStepIcon-root.MuiStepIcon-completed {\n  color: #a50808 !important;\n}\n\n.MuiStepIcon-active {\n  color: #a50808 !important;\n}\n\n.MuiFormControl-root {\n  width: 100%;\n}\n\n.MuiPickersToolbar-toolbar,\n.MuiPickersDay-daySelected,\n.MuiPickersClock-pin,\n.MuiPickersClockPointer-pointer,\n.MuiPickersClockNumber-clockNumberSelected {\n  background-color: #a50808 !important;\n}\n\n.MuiButton-label {\n  color: #a50808 !important;\n}\n\n.MuiPickersClockPointer-thumb {\n  border-color: #a50808 !important;\n}\n\n.MuiStepLabel-root {\n  display: contents !important;\n}\n\n.MuiStepLabel-iconContainer {\n  padding-bottom: 8px !important;\n}\n\n.MuiStepIcon-root {\n  margin: auto !important;\n}\n\n.MuiStepper-horizontal {\n  width: 30rem !important;\n  margin: auto !important;\n}\n\n.dropdown-toggle::after {\n  content: none !important;\n}\n\n.ellipses:hover {\n  background-color: lightskyblue;\n}\n\n.column__width {\n  padding: 0 !important;\n}\n\n.payment__modal {\n}\n\n@media only screen and (max-width: 812px) {\n  .MuiStepper-horizontal {\n    width: 22rem !important;\n    margin: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
