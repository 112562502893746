// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageStep .MuiStepper-horizontal {
  width: 18rem !important;
  margin: auto !important;
}

.profile__picture {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.avatar__image {
  background-color: #d9d9d94d;
  border-radius: 50%;
}

.image__wrapper {
  background-color: #d9d9d94d;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/settings/css/viewUserDetails.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".manageStep .MuiStepper-horizontal {\n  width: 18rem !important;\n  margin: auto !important;\n}\n\n.profile__picture {\n  height: 70px;\n  width: 70px;\n  border-radius: 50%;\n}\n\n.avatar__image {\n  background-color: #d9d9d94d;\n  border-radius: 50%;\n}\n\n.image__wrapper {\n  background-color: #d9d9d94d;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
