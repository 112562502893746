const ReimbursementReasons = [
  {
    _id: '6516b4f0f83fd70032a312c2',
    reason: 'Dining Benefit',
  },
  {
    _id: '6516b4f0f83fd70032a312b8',
    reason: 'Equipment Allowance',
  },
  {
    _id: '6516b4f0f83fd70032a312b4',
    reason: 'Fuel Reimbursement',
  },
];

export default ReimbursementReasons;